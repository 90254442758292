<template>
  <div class="container">
    <div v-if="active == 0 && queryDetail !== null">
      <van-cell-group>
        <div class="info-one block-padding">
          <span class="label-name">变量名</span>
          <span class="value-query">{{ queryDetail.field_label }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">当前值</span>
          <span class="value-query">{{ queryDetail.current_value }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">质疑时值</span>
          <div class="value-query">
            <van-icon
              name="question"
              v-if="queryDetail.relate_value.length"
              @click="onClickShow"
              size="20px"
            ></van-icon>
            <span>{{ queryDetail.field_value }}</span>
          </div>
        </div>
      </van-cell-group>
      <van-cell-group>
        <div class="info-one block-padding">
          <span class="label-name">质疑用户</span>
          <span class="value-query"
            >{{ queryDetail.from_name }}({{ queryDetail.permission_role }}){{
              queryDetail.mobile
            }}</span
          >
        </div>
        <div class="info-one block-padding">
          <span class="label-name">质疑时间</span>
          <span class="value-query">{{ queryDetail.create_date }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">质疑文本</span>
          <span class="value-query">{{ queryDetail.add_reason }}</span>
        </div>
      </van-cell-group>
      <van-cell-group v-if="queryClose == 1">
        <van-field
          v-model="queryDetail.my_close_reason"
          type="spanarea"
          placeholder="关闭原因"
          :border="true"
        >
          <div slot="label">
            <span class="label-name">关闭原因</span>
          </div>
          <div slot="input">
            <el-autocomplete
              class="inline-input"
              v-model="queryDetail.my_close_reason"
              :fetch-suggestions="queryCloseSearch"
              placeholder="关闭原因"
              @select="handleSelect"
            ></el-autocomplete>
          </div>
        </van-field>
      </van-cell-group>
      <van-cell-group v-if="queryReply == 1">
        <!-- <div class="info-one block-padding">
          <span class="label-name">质疑用户</span>
          <span class="value-query">{{ queryDetail.from_name }}</span>
        </div> -->
        <div>
          <rh-form
            :labelWidth="100"
            @dataChange="onDataChange"
            :formChild="formChild"
          ></rh-form>
        </div>
        <van-field
          v-model="queryDetail.my_close_reason"
          type="spanarea"
          placeholder="澄清原因"
          :border="true"
        >
          <div slot="label">
            <span class="label-name">澄清原因</span>
          </div>
          <div slot="input">
            <el-autocomplete
              class="inline-input"
              v-model="queryDetail.my_close_reason"
              :fetch-suggestions="queryReplySearch"
              placeholder="澄清原因"
              @select="handleSelect"
            ></el-autocomplete>
          </div>
        </van-field>
      </van-cell-group>
      <van-button
        v-if="queryClose == 1"
        type="primary"
        @click="onCloseQuery()"
        style="margin-top: 8px"
        block
        >关闭质疑</van-button
      >
      <van-button
        v-if="queryReply == 1"
        type="primary"
        @click="onClearQuery"
        style="margin-top: 8px"
        block
        >澄清</van-button
      >
    </div>
    <div v-if="active == 1 && queryDetail !== null">
      <van-cell-group>
        <div class="info-one block-padding">
          <span class="label-name">字段标签</span>
          <span class="value-query">{{ queryDetail.field_label }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">当前值</span>
          <span class="value-query">{{ queryDetail.current_value }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">质疑时值</span>
          <div class="value-query">
            <van-icon
              name="question"
              v-if="queryDetail.relate_value.length"
              @click="onClickShow"
              size="20px"
            ></van-icon>
            <span>{{ queryDetail.field_value }}</span>
          </div>
        </div>
      </van-cell-group>
      <van-cell-group>
        <div class="info-one block-padding">
          <span class="label-name">质疑用户</span>
          <span class="value-query"
            >{{ queryDetail.from_name }}({{ queryDetail.permission_role }}){{
              queryDetail.mobile
            }}</span
          >
        </div>
        <div class="info-one block-padding">
          <span class="label-name">质疑时间</span>
          <span class="value-query">{{ queryDetail.create_date }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">质疑文本</span>
          <span class="value-query">{{ queryDetail.add_reason }}</span>
        </div>
      </van-cell-group>
      <van-cell-group>
        <div class="info-one block-padding">
          <span class="label-name">澄清值</span>
          <span class="value-query">{{ queryDetail.reply_value }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">澄清人</span>
          <span class="value-query">{{ queryDetail.reply_name }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">澄清时间</span>
          <span class="value-query">{{ queryDetail.reply_date }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">澄清原因</span>
          <span class="value-query">{{ queryDetail.reply_reason }}</span>
        </div>
      </van-cell-group>
      <van-cell-group v-if="queryClose == 1 || queryAdd == 1">
        <van-field
          v-model="queryDetail.my_close_reason"
          type="spanarea"
          placeholder="关闭/激活原因"
          border
        >
          <div slot="label">
            <span class="label-name">关闭/激活原因</span>
          </div>
          <div slot="input">
            <el-autocomplete
              class="inline-input"
              v-model="queryDetail.my_close_reason"
              :fetch-suggestions="queryActiveSearch"
              placeholder="关闭/激活原因"
              @select="handleSelect"
            ></el-autocomplete>
          </div>
        </van-field>
      </van-cell-group>
      <div
        class="info-one"
        style="
          justify-content: space-around;
          align-items: center;
          margin-top: 8px;
        "
      >
        <van-button
          v-if="queryClose == 1"
          type="primary"
          style="width: 200px"
          @click="onCloseQuery('请输入关闭/激活原因')"
          block
          >关闭质疑</van-button
        >
        <van-button
          type="primary"
          v-if="queryAdd == 1"
          @click="onReactivateQuery"
          >重新激活</van-button
        >
      </div>
    </div>
    <div v-if="active == 2 && queryDetail !== null">
      <van-cell-group>
        <div class="info-one block-padding">
          <span class="label-name">字段标签</span>
          <span class="value-query">{{ queryDetail.field_label }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">当前值</span>
          <span class="value-query">{{ queryDetail.current_value }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">质疑时值</span>
          <div class="value-query">
            <van-icon
              v-if="queryDetail.relate_value.length"
              name="question"
              @click="onClickShow"
              size="20px"
            ></van-icon>
            <span>{{ queryDetail.field_value }}</span>
          </div>
        </div>
      </van-cell-group>
      <van-cell-group>
        <div class="info-one block-padding">
          <span class="label-name">质疑用户</span>
          <span class="value-query"
            >{{ queryDetail.from_name }}({{ queryDetail.permission_role }}){{
              queryDetail.mobile
            }}</span
          >
        </div>
        <div class="info-one block-padding">
          <span class="label-name">质疑时间</span>
          <span class="value-query">{{ queryDetail.create_date }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">质疑文本</span>
          <span class="value-query">{{ queryDetail.add_reason }}</span>
        </div>
      </van-cell-group>
      <van-cell-group>
        <div class="info-one block-padding">
          <span class="label-name">澄清值</span>
          <span class="value-query">{{ queryDetail.reply_value }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">澄清人</span>
          <span class="value-query">{{ queryDetail.reply_name }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">澄清时间</span>
          <span class="value-query">{{ queryDetail.reply_date }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">澄清原因</span>
          <span class="value-query">{{ queryDetail.reply_reason }}</span>
        </div>
      </van-cell-group>
      <van-cell-group>
        <div class="info-one block-padding">
          <span class="label-name">关闭人</span>
          <span class="value-query">{{ queryDetail.confirm_name }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">关闭时间</span>
          <span class="value-query">{{ queryDetail.confirm_date }}</span>
        </div>
        <div class="info-one block-padding">
          <span class="label-name">关闭原因</span>
          <span class="value-query">{{ queryDetail.confirm_reason }}</span>
        </div>
      </van-cell-group>
    </div>
    <van-overlay :show="showOverlay" @click="onClickHide">
      <div class="wrapper">
        <div class="block" @click.stop="">
          <div v-if="queryDetail">
            <van-cell
              class="rh-list"
              v-for="item in queryDetail.relate_value"
              :key="item.id"
            >
              <div hover-class="rh-hover" hover-stay-time="70">
                <div class="info-one">
                  <span class="label-name">访视</span>
                  <span class="value-query">{{ item.folder_name }}</span>
                </div>
                <div class="info-one">
                  <span class="label-name">表单</span>
                  <span class="value-query">{{ item.form_name }}</span>
                </div>
                <div class="info-one">
                  <span class="label-name">变量名</span>
                  <span class="value-query">{{ item.field_label }}</span>
                </div>
                <div class="info-one">
                  <span class="label-name">变量值</span>
                  <span class="value-query">{{ item.value }}</span>
                </div>
              </div>
            </van-cell>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import { Button, Cell, CellGroup, Overlay, Field, Icon, Toast } from "vant";
import service from "../js/service";
import qs from "qs";
import wx from "weixin-js-sdk";
import rhForm from "../components/rh-form.vue";
import { handleValues } from "../js/utils/utils";
import { Autocomplete } from "element-ui";
Vue.use(Autocomplete);

export default {
  name: "queryDetail",
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Overlay.name]: Overlay,
    [Field.name]: Field,
    [Icon.name]: Icon,
    rhForm,
  },
  data() {
    return {
      active: 0,
      queryDetail: null,
      showOverlay: false,
      envId: "",
      projectId: "",
      roleId: "",
      envName: "",
      id: "",
      queryAdd: 0,
      queryClose: 0,
      queryReply: 0,
      formChild: [],
      replyValue: "",
      projectConfigValue: null,
    };
  },
  mounted() {
    const {
      envId,
      projectId,
      roleId,
      envName,
      id,
      queryAdd,
      queryClose,
      queryReply,
    } = this.$route.query;
    this.envId = envId || "";
    this.projectId = projectId || "";
    this.roleId = roleId || "";
    this.envName = envName || "";
    // this.active = active || "";
    this.id = id || "";
    this.queryAdd = queryAdd || 0;
    this.queryClose = queryClose || 0;
    this.queryReply = queryReply || 0;
    this.getDetailFun();
  },
  methods: {
    async getDetailFun() {
      let obj = { project_id: this.projectId, env_id: this.envId, id: this.id };
      let str = qs.stringify(obj);
      this.queryDetail = null;
      let toast = Toast.loading("加载中...");
      this.formChild = [];
      this.projectConfigValue = null;
      const res = await service.sendReq(
        "get-subject-query-details?" + str,
        null,
        "get"
      );
      if (res.data.code === 200) {
        const data = res.data.data;
        this.projectConfigValue = data.projectConfigValue;
        this.active = data.status;
        this.queryDetail = { ...data, my_close_reason: "" };
        let obj = {
          control_type: data.control_type,
          data_dictionary: data.data_dictionary,
          data_format: data.data_format,
          default_value: data.default_value,
          field_label: "澄清值",
          field_oid: data.field_oid,
          is_readonly: "0",
        };
        this.formChild.push(handleValues(obj));
      }
      toast.clear();
    },
    onClickShow() {
      this.showOverlay = true;
    },
    onClickHide() {
      this.showOverlay = false;
    },
    onCloseQuery(message = "请输入关闭原因") {
      if (!this.queryDetail.my_close_reason) {
        Toast({
          message: message,
          position: "top",
        });
        return;
      }
      this.handleCloseQuery();
    },
    async handleCloseQuery() {
      const res = await service.sendReq(
        "close-query",
        {
          project_id: this.projectId,
          env_name: this.envName,
          role_id: this.roleId,
          env_id: this.envId,
          query_id: this.id,
          confirm_reason: this.queryDetail.my_close_reason,
        },
        "post"
      );
      if (res.data.code === 200) {
        let numberOfEntries = window.history.length;
        let message = res.data.msg;
        message = JSON.stringify(message);
        Toast(message);
        if (numberOfEntries >= 2) {
          this.$router.back(-1);
        } else {
          wx.miniProgram.postMessage({ data: { foo: "bar" } });
          wx.miniProgram.navigateBack({
            delta: 1,
          });
        }
      } else {
        let message = res.data.msg;
        message = JSON.stringify(message);
        Toast({
          message: message,
          position: "top",
        });
      }
    },
    onReactivateQuery() {
      if (!this.queryDetail.my_close_reason) {
        Toast({
          message: "请输入关闭/激活原因",
          position: "top",
        });
        return;
      }
      this.handleReactivateQuery();
    },
    async handleReactivateQuery() {
      const res = await service.sendReq(
        "reactivate-query",
        {
          project_id: this.projectId,
          env_name: this.envName,
          role_id: this.roleId,
          env_id: this.envId,
          query_id: this.id,
          confirm_reason: this.queryDetail.my_close_reason,
        },
        "post"
      );
      if (res.data.code === 200) {
        let numberOfEntries = window.history.length;
        let message = res.data.msg;
        message = JSON.stringify(message);
        Toast(message);
        if (numberOfEntries >= 2) {
          this.$router.back(-1);
        } else {
          wx.miniProgram.postMessage({ data: { foo: "bar" } });
          wx.miniProgram.navigateBack({
            delta: 1,
          });
        }
      } else {
        let message = res.data.msg;
        message = JSON.stringify(message);
        Toast({
          message: message,
          position: "top",
        });
      }
    },
    onDataChange(val) {
      let value = val[0].value;
      //   console.log(value);
      this.replyValue = value;
    },
    async handleClearQuery() {
      const res = await service.sendReq(
        "clear-query",
        {
          project_id: this.projectId,
          env_name: this.envName,
          role_id: this.roleId,
          env_id: this.envId,
          query_id: this.id,
          reply_reason: this.queryDetail.my_close_reason,
          reply_value: this.replyValue,
        },
        "post"
      );
      if (res.data.code === 200) {
        let numberOfEntries = window.history.length;
        console.log(numberOfEntries);
        let message = res.data.msg;
        message = JSON.stringify(message);
        Toast(message);
        if (numberOfEntries >= 2) {
          this.$router.back(-1);
        } else {
          wx.miniProgram.postMessage({ data: { foo: "bar" } });
          wx.miniProgram.navigateBack({
            delta: 1,
          });
        }
      } else {
        let message = res.data.msg;
        message = JSON.stringify(message);
        Toast(message);
      }
    },
    onClearQuery() {
      if (!this.queryDetail.my_close_reason) {
        Toast({
          message: "请输入澄清原因",
          position: "top",
        });
        return;
      }
      this.handleClearQuery();
    },
    handleSelect(item) {
      console.log(item);
    },
    queryReplySearch(queryString, cb) {
      if (this.projectConfigValue) {
        let reply_reason = this.projectConfigValue.reply_reason.map((item) => {
          return { value: item };
        });
        cb(reply_reason);
      } else {
        cb();
      }
    },
    queryCloseSearch(queryString, cb) {
      if (this.projectConfigValue) {
        let close_reason = this.projectConfigValue.close_reason.map((item) => {
          return { value: item };
        });
        cb(close_reason);
      } else {
        cb();
      }
    },
    queryActiveSearch(queryString, cb) {
      if (this.projectConfigValue) {
        let active_reason = this.projectConfigValue.active_reason.map(
          (item) => {
            return { value: item };
          }
        );
        cb(active_reason);
      } else {
        cb();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
.container {
  padding: 10px;
  box-sizing: border-box;
  height: 100%;
}

.rh-list {
  display: block;
  padding: 0;
}

.subject-item-w {
  display: flex;
  align-items: center;
  padding: 16px;
}

.info-one {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.label-name {
  width: 100px;
  display: block;
  color: #646566;
  text-align: left;
  word-wrap: break-word;
  font-size: 14px;
}

.value-query {
  flex: 1;
  display: block;
  text-align: left;
}

.block-padding {
  padding: 10px 16px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 90%;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}
.rh-list {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px $bg-grey;
  padding: 16px;
  box-sizing: border-box;
  margin: 10px 0 0;
}

.rh-hover {
  background-color: rgb(241, 241, 241) !important;
}
</style>